import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import mqtt from "precompiled-mqtt";
import { Paper } from "@material-ui/core";
//import net from "net";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function ReaderConfigSocket(props) {
  const { business } = props;
  const [readerMessages, setReaderMessages] = useState(false);

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const eventResults = useSelector((state) => state.events.eventResults);

  const eventParticipantsData = useSelector(
    (state) => state.registry.eventParticipants
  );
  const eventParticipantsRequested = useSelector(
    (state) => state.registry.eventParticipantsRequested
  );
  const eventParticipantsEventIndex = useSelector(
    (state) => state.registry.eventParticipantsEventIndex
  );
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );

  const puntosControl = useSelector((state) =>
    state.events.puntosControl.val()
  );

  const CLOUDAMQP_MQTT_URL = new URL(
    "mqtt://dwgbcfbz:Cta2YVRF26RNNwsr5E4AJEczEg38NiBv@shark.rmq.cloudamqp.com:1883"
  );
  var mqtt_url = CLOUDAMQP_MQTT_URL;
  var auth = (mqtt_url.auth || ":").split(":");
  var url =
    "wss://ef2511e142a44d51abb131d31e5366e8.s1.eu.hivemq.cloud:8884/mqtt"; //mqtt_url.host;
  console.log("CLOUDAMQP_MQTT_URL", CLOUDAMQP_MQTT_URL);

  //username: auth[0] + ":" + auth[0] if you are on a shared instance
  var options = {
    protocol: "wss",
    username: "dwgbcfbz",
    password: "Cta2YVRF26RNNwsr5E4AJEczEg38NiBv",
  };

  //var client = net.Socket();

  const reader = "fx96005fa548";
  useEffect(() => {
    // client.connect(1710, reader, function() {
    //   console.log("Connected");
    //   client.write("Hello, server! Love, Client.");
    // });
  }, []);

  const classes = useStyles();

  function publish(topic, message) {
    client.publish(
      topic,
      JSON.stringify(message),
      { qos: 0, retain: false },
      function(error) {
        if (error) {
          console.log(error);
        } else {
          console.log("Published");
        }
      }
    );
  }

  function startReadingMQTT(readerTo) {
    const topic = readerTo + "/control";
    const json = {
      command: "start",
      command_id: "1",
      payload: {},
    };
    publish(topic, json);
  }
  function stopReadingMQTT(readerTo) {
    const topic = readerTo + "/control";
    const json = {
      command: "stop",
      command_id: "1",
      payload: {},
    };
    publish(topic, json);
  }
  function getMode(readerTo) {
    const topic = readerTo + "/control";
    const json = {
      command: "get_mode",
      command_id: "1",
      payload: {},
    };
    publish(topic, json);
  }
  function setMode(reader, controlPoint) {
    let antennasArray = [1, 2, 3, 4, 5, 6, 7, 8];
    let antennasPower = [30.0, 30.0, 30.0, 30.0, 30.0, 30.0, 30.0, 30.0];
    if (reader.antennas === 4) {
      antennasArray = [1, 2, 3, 4];
      antennasPower = [30.0, 30.0, 30.0, 30.0];
    }
    const topic = reader.readerId + "/control";
    const json = {
      command: "set_mode",
      command_id: "1",
      payload: {
        antennas: antennasArray,
        filter: {
          match: "prefix",
          operation: "include",
          value: "44415348504F5254",
        },
        reportFilter: { duration: 20, type: "RADIO_WIDE" },
        tagMetaData: [
          { userDefined: eventDetail.iDEvento + "/" + controlPoint + "/true" },
        ],
        transmitPower: antennasPower,
        type: "SIMPLE",
        environment: "HIGH_INTERFERENCE",
      },
    };
    publish(topic, json);
  }
  function getConfig(readerTo) {
    const topic = readerTo + "/control";
    const json = {
      command: "get_config",
      command_id: "1",
      payload: {},
    };
    publish(topic, json);
  }

  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12} sm={12}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={12}>
            <Typography gutterBottom variant={"h1"} component="h2">
              Lectores del evento
            </Typography>{" "}
            {/* 1) lista de readers conectados. tengo que poder poner el id del
            reader 2) Cada Reader debe pedir el mode para tener las antenas
            activas y hora. 3) Botón para iniciar lecturas. */}
          </Grid>
          <Grid item xs={12} style={{ margin: "10px" }}></Grid>

          {}
          {eventConfiguration &&
            eventConfiguration.timingConfig &&
            eventConfiguration.timingConfig.readers &&
            Object.values(eventConfiguration.timingConfig.readers).map(
              (reader) => (
                <Paper style={{ backgroundColor: "#e0e0e0", padding: 30 }}>
                  <Grid container direction="row" alignItems="flex-start">
                    <Grid item xs={12} sm={12}>
                      <Typography gutterBottom variant={"h4"} component="h2">
                        Ubicación del lector: {reader.location}
                      </Typography>{" "}
                      <Typography
                        gutterBottom
                        variant={"subtitle1"}
                        component="h2"
                      >
                        Identificador del lector: {reader.readerId}
                      </Typography>{" "}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Button
                        style={{
                          margin: 10,
                          padding: 10,
                          fontSize: 20,
                        }}
                        onClick={() => startReadingMQTT(reader.readerId)}
                        color="primary"
                        variant="contained"
                      >
                        {" "}
                        Iniciar Lecturas
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        style={{
                          margin: 10,
                          padding: 10,
                          fontSize: 20,
                        }}
                        onClick={() => stopReadingMQTT(reader.readerId)}
                        color="primary"
                        variant="contained"
                      >
                        {" "}
                        Detener Lecturas
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        style={{ marginTop: 30 }}
                        gutterBottom
                        variant={"h5"}
                        component="h2"
                      >
                        Selecciona el punto de control
                      </Typography>{" "}
                    </Grid>
                    {puntosControl &&
                      Object.values(puntosControl).map((control) => (
                        <Grid item xs={12} sm={3}>
                          <Button
                            style={{
                              margin: 10,
                              padding: 10,
                              fontSize: 20,
                            }}
                            onClick={() => setMode(reader, control.kNroPunto)}
                            color="secondary"
                            variant="contained"
                          >
                            {control.descripcionFinalPunto}
                          </Button>
                        </Grid>
                      ))}
                    {/* <Grid item xs={12} sm={6}>
                      <Button
                        style={{
                          margin: 10,
                          padding: 10,
                          fontSize: 20,
                        }}
                        onClick={() => getConfig(reader.readerId)}
                        color="primary"
                        variant="contained"
                      >
                        Obtener Configuración
                      </Button>
                    </Grid> */}
                    <Grid item xs={12} sm={12}>
                      <Button
                        style={{
                          margin: 10,
                          padding: 10,
                          fontSize: 20,
                        }}
                        onClick={() => getMode(reader.readerId)}
                        color="primary"
                        variant="contained"
                      >
                        Obtener Modo de operación del lector
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {readerMessages &&
                        readerMessages[reader.readerId] &&
                        JSON.stringify(readerMessages[reader.readerId])}
                    </Grid>
                  </Grid>
                </Paper>
              )
            )}
          {/* <Grid item xs={12} sm={6} style={{ margin: 30 }}>
            <Button
              style={{ padding: 50, fontSize: 20, width: 500 }}
              onClick={() => configReader()}
              color="primary"
              variant="contained"
            >
              {" "}
              Obtener Config{" "}
            </Button>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
}
