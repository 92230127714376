import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";

import SimpleDialogDemo from "../../LayoutContainers/SimpleDialog";
//import LoginForm from "../Login/LoginForm";
import EventRegistryPublicForm from "./EventRegistryPublicForm";
import {
  configuracionEvento,
  detalleEvento,
  eventSubEventsRegistry,
} from "../../../../data-store/actions/events-actions";
import { registryConfiguration } from "../../../../data-store/actions/registry-actions";
import RegistryConfirmationPublic from "./RegistryConfirmation/RegistryConfirmationPublic";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
    margin: "0px",
  },
  paper: {
    padding: "0px",
    backgroundColor: "#283747",
  },
  color: {
    padding: "0px",
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function EventRegistryPublic(props) {
  const {
    eventIdDashport,
    title,
    textBottom,
    name,
    eventLogo,
    image,
    renderType,
  } = props;
  const dispatch = useDispatch();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const registry = query.get("registry");
  const event = query.get("event");
  const download = query.get("download");

  //const athleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistryData = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const registryConfigurationData = useSelector(
    (state) => state.registry.registryConfiguration
  );
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  console.log("eventSubEventsRegistryData", eventSubEventsRegistryData);

  useEffect(() => {
    console.log("getRegistryDATA", eventIdDashport);
    if (eventIdDashport) {
      console.log("getRegistryDATA", eventIdDashport);
      dispatch(detalleEvento(eventIdDashport));
      dispatch(eventSubEventsRegistry(eventIdDashport));
      dispatch(registryConfiguration(eventIdDashport));
    }
    if (registry && eventIdDashport === event) {
      console.log("registry", registry);
      setShowConfirmation(true);
    }
  }, [eventIdDashport, registry]);

  if (
    eventSubEventsRegistryData === undefined ||
    Object.values(eventSubEventsRegistryData).length === 0 ||
    registryConfigurationData === undefined
  ) {
    return <div></div>;
  }

  function openEditMode(result) {
    console.log("Open edit mode");
  }
  function openModal() {
    console.log("Open registry");
    setOpen(true);
  }
  function renderDistanceConfigResumen(data) {
    data = Object.values(data).sort((a, b) => a.order - b.order);
    let view = [];
    for (var i in data) {
      if (!data[i].hide) {
        view.push(
          <Grid item xs={12}>
            <Typography
              color="secondary"
              gutterBottom
              variant={"h4"}
              component="h2"
            >
              {data[i].descripcionModalidad}
            </Typography>
            <Typography
              color="primary"
              gutterBottom
              variant={"subtitle1"}
              component="h2"
            >
              Precio : {data[i].configModalidad.precio.preventa_1.precio_1}{" "}
              {registryConfigurationData.configuracion.moneda &&
                Object.values(
                  registryConfigurationData.configuracion.moneda
                ).filter((a) => a.tipo === "base")[0].codigo}
              {registryConfigurationData.configuracion.moneda === undefined &&
                "MXN"}
            </Typography>
          </Grid>
        );
      }
    }
    return view;
  }
  function renderDistanceConfig(data) {
    data = Object.values(data).sort((a, b) => a.order - b.order);

    let view = [];
    for (var i in data) {
      if (!data[i].hide) {
        view.push(
          <Grid item xs={12}>
            <Typography
              color="primary"
              gutterBottom
              variant={"h4"}
              component="h2"
            >
              {data[i].descripcionModalidad}
            </Typography>
            <Typography
              color="primary"
              gutterBottom
              variant={"subtitle1"}
              component="h2"
            >
              Precio : {data[i].configModalidad.precio.preventa_1.precio_1}{" "}
              {registryConfigurationData.configuracion.moneda &&
                Object.values(
                  registryConfigurationData.configuracion.moneda
                ).filter((a) => a.tipo === "base")[0].codigo}
              {registryConfigurationData.configuracion.moneda === undefined &&
                "MXN"}
            </Typography>
            <Typography
              color="primary"
              gutterBottom
              variant={"subtitle1"}
              component="h2"
            >
              Categorías
            </Typography>
          </Grid>
        );
        const ramas = data[i].ramaCat;
        for (var e in ramas) {
          const categorias = ramas[e].categorias;
          let cateogriesView = [];
          for (var f in categorias) {
            if (!categorias[f].hide) {
              cateogriesView.push(
                <Typography
                  key={e + "-" + f + "title"}
                  color="secondary"
                  gutterBottom
                  variant={"subtitle1"}
                  component="h2"
                >
                  {categorias[f].nombreCategoria} -{categorias[f].edadDesde}
                  {categorias[f].edadHasta === 200
                    ? " y mayores"
                    : " a " + categorias[f].edadHasta + " años"}
                </Typography>
              );
            }
          }
          view.push(
            <Grid item xs={12} sm={6} alignItems="center">
              <Typography
                key={e + "modalidad"}
                color="secondary"
                gutterBottom
                variant={"h5"}
                component="h2"
              >
                {ramas[e].rama === "V" ? "Varonil" : "Femenil"}
                {cateogriesView}
              </Typography>
            </Grid>
          );
        }
      }
    }
    return view;
  }
  const view = (
    <Grid
      container
      direction="row"
      wrap="wrap"
      alignItems="center"
      alignContent="center"
      onClick={() => openModal()}
    >
      <Grid item xs={12}>
        <Typography color="primary" gutterBottom variant={"h3"} component="h2">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ padding: "0px" }}>
        {renderType === "image" &&
          eventDetail &&
          eventSubEventsRegistryData && (
            <img alt="imagen" src={image} width={"100%"} />
          )}

        {renderType === "viewFull" &&
          eventDetail &&
          eventSubEventsRegistryData &&
          registryConfigurationData && (
            <Grid
              container
              direction="row"
              wrap="wrap"
              onClick={() => openModal()}
            >
              {renderDistanceConfig(eventSubEventsRegistryData)}
            </Grid>
          )}
        {renderType === "view" &&
          eventDetail &&
          eventSubEventsRegistryData &&
          registryConfigurationData && (
            <Grid
              container
              direction="row"
              wrap="wrap"
              onClick={() => openModal()}
            >
              {renderDistanceConfigResumen(eventSubEventsRegistryData)}
            </Grid>
          )}
      </Grid>
      <Grid item xs={12}>
        <Typography color="primary" variant={"h5"} component="h2">
          {textBottom}
        </Typography>
      </Grid>
    </Grid>
  );
  return (
    <div>
      {" "}
      <div style={{ padding: 20 }}>
        {showConfirmation && (
          <SimpleDialogDemo
            fullScreen={false}
            buttonText={"Confirmación de inscripción"}
            openDefault={true}
          >
            <div
              style={{
                padding: 20,
              }}
            >
              <RegistryConfirmationPublic event={event} registry={registry} />
            </div>
          </SimpleDialogDemo>
        )}
      </div>
      <SimpleDialogDemo fullScreen={false} view={view}>
        {/* {athleteAccount == null && (
        <Grid
          container
          direction="row"
          wrap="wrap"
          alignItems="center"
          alignContent="center"
          onClick={() => openModal()}
        >
          <Grid
            item
            xs={12}
            alignItems="center"
            style={{ textAlign: "center" }}
          >
            <Typography
              color="primary"
              gutterBottom
              variant={"h5"}
              component="h2"
            >
              Crea tu cuenta de usuario para continuar
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LoginForm />
          </Grid>
        </Grid>
      )} */}
        <EventRegistryPublicForm eventIdDashport={eventIdDashport} />
        {/* {athleteAccount != null && (
      <EventRegistryPublicForm eventIdDashport={eventIdDashport} />
      )} */}
      </SimpleDialogDemo>
    </div>
  );
}
