import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { CSVLink } from "react-csv";
import { fechahora } from "../../../../../../../services/utils";
import SimpleDialogDemo from "../../../../../../pages/Modals/SimpleDialog";
import jsPDF from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function PrintResultsCSV(props) {
  const { business } = props;

  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventSubEvents = useSelector((state) => state.events.eventSubEvents);
  const eventDetail = useSelector((state) => state.events.eventDetail);

  const eventResults = useSelector((state) => state.events.eventResults);

  const [csvLabels, setCSVLabels] = useState([
    { label: "Número", key: "num" },
    { label: "Posición General", key: "pG" },
    { label: "Posición Rama", key: "pR" },
    { label: "Posición Categoría", key: "pCat" },
    { label: "Nombre", key: "nombre" },
    { label: "Genero", key: "gen" },
    { label: "Categoría", key: "cat" },
    { label: "Modalidad", key: "mod" },
    { label: "Tiempo Oficial", key: "to" },
    { label: "Tiempo Real", key: "tc" },
    { label: "Fecha hora Meta", key: "fhm" },
    { label: "Registro Salida", key: "registroSalida" },
    { label: "Serie", key: "serie" },
  ]);
  const [csvReport, setCSVReport] = useState([]);
  const [csvReportFinishers, setCSVReportFinishers] = useState([]);
  const [arrayCategories, setarrayCategories] = useState([]);

  useEffect(() => {
    if (eventResults && eventSubEvents) {
      setarrayCategories(getCategoryArray(eventSubEvents));
      setCSVReport(eventResults);
      setCSVReportFinishers(eventResults.filter((a) => a.pG !== 0));
    }
  }, [eventResults]);

  function getCategoryArray(eventSubEventsFunc) {
    let array = {};
    for (var m in eventSubEvents) {
      for (var g in eventSubEvents[m]) {
        for (var c in eventSubEvents[m].ramaCat[g]) {
          array[m + "-" + g + "-" + c] =
            eventSubEvents[m].ramaCat[g].categorias[c].cantidadGanadores;
        }
      }
    }
  }
  function downloadPDFWinners() {
    const header = ["Número", "Posición", "Nombres", "Tiempo Oficial"];
    console.log("PDF Winners");
    const doc = new jsPDF();
    var lMargin = 15; //left margin in mm
    var rMargin = 15; //right margin in mm
    var pdfInMM = 210; // width of A4 in mm

    const title = "Ganadores";
    console.log(title);
    if (title) {
      var lines = doc.splitTextToSize(title, pdfInMM - lMargin - rMargin);
      doc.text(lMargin, 15, lines);

      //    doc.text(7, 15, title);
    }
    autoTable(doc, {
      startY: 30,
      head: [header],
      body: eventResults
        .filter((a) => a.pG !== 0)
        .map((a) => [a.num, a.pCat, a.nombre, a.to]),
      margin: { horizontal: 7 },
      columnStyles: {
        0: { halign: "center" },
        1: { halign: "center" },
        3: { halign: "center" },
      }, // Cells in first column centered and green
      headStyles: {
        0: { halign: "center" },
        1: { halign: "center" },
        3: { halign: "center" },
        fillColor: [255, 50, 50],
      }, // Cells in first column centered and green
    });

    doc.save(
      "Resultados Finishers - " +
        eventDetail.nombreEvento +
        " - " +
        fechahora() +
        ".pdf"
    );
  }
  return (
    <Grid container direction="row" alignItems="flex-start">
      <Grid item xs={12} sm={12}>
        {eventResults && (
          <Grid container direction="row" alignItems="flex-start">
            <Grid item xs={12} sm={12} alignContent="center">
              <CSVLink
                style={{ padding: 5 }}
                filename={
                  "Resultados - " +
                  eventDetail.nombreEvento +
                  " - " +
                  fechahora() +
                  ".csv"
                }
                data={csvReport}
                headers={csvLabels}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: 20, margin: 10 }}
                >
                  Descargar todos los resultados en CSV
                </Button>
              </CSVLink>
            </Grid>
            <Grid item xs={12} sm={12} alignContent="center">
              <CSVLink
                style={{ padding: 5 }}
                filename={
                  "Resultados Finishers - " +
                  eventDetail.nombreEvento +
                  " - " +
                  fechahora() +
                  ".csv"
                }
                data={csvReportFinishers}
                headers={csvLabels}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: 20, margin: 10 }}
                >
                  Descargar Finishers CSV
                </Button>
              </CSVLink>
            </Grid>
            {/* <Grid item xs={12} sm={12} alignContent="center">
              <Button
                variant="contained"
                color="primary"
                style={{ padding: 20, margin: 10 }}
                onClick={() => downloadPDFWinners()}
              >
                Descargar PDF Ganadores
              </Button>
            </Grid> */}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
