import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDepositsList } from "../../../../../../data-store/actions/events-actions";
import { saveRegistryDataToGroupedData } from "../../../../../../data-store/actions/organizers-actions";
import { fecha } from "../../../../../../services/utils";
import EditableField from "../../../../../EditableField/EditableField";
import CSVButton from "../../../../AuthorizedUsers/Reports/CSVButton";
import SimpleDialogDemo from "../../../../Modals/SimpleDialog";
import AddAuthUserToDeposits from "./AddAuthUserToDeposits";
import AddItem from "./AddItem";
import DownloadItems from "./DownloadItems";
import EditItem from "./EditItem";
import SaveAccountToMaster from "./SaveAccountToMaster";

export default function ItemsTable({ jsonDashboard }) {
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const eventParticipants = useSelector(
    (state) => state.registry.eventParticipants
  );
  const eventDepositsList = useSelector(
    (state) => state.events.eventDepositsList
  );
  const eventDepositsUsers = useSelector(
    (state) => state.events.eventDepositsUsers
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      (eventDepositsUsers &&
        eventDepositsUsers[userID] &&
        eventDepositsUsers[userID].authorized) ||
      atleteAccount.perfil === 1
    ) {
      dispatch(getDepositsList("evento_" + eventDetail.iDEvento));
    }
  }, []);

  if (!eventDepositsList && atleteAccount.perfil !== 1) {
    return <br></br>;
  }

  let showFormToAdd;
  if (atleteAccount.perfil === 1) {
    showFormToAdd = (
      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={6}>
          <SimpleDialogDemo buttonText={"Agregar Registros"} fullScreen={false}>
            <AddItem />
          </SimpleDialogDemo>
        </Grid>
        <Grid item xs={6}>
          <SimpleDialogDemo
            buttonText={"Agregar Usuarios autorizados"}
            fullScreen={false}
          >
            <AddAuthUserToDeposits />
          </SimpleDialogDemo>
        </Grid>
        <Grid item xs={6}>
          <Button
            style={{ margin: 30, padding: 50 }}
            color="primary"
            variant="contained"
            onClick={() =>
              saveRegistryDataToGroupedData(
                eventDetail,
                eventSubEventsRegistry,
                eventParticipants
              )
            }
          >
            Guardar Base de datos de inscritos en listado agrupado
          </Button>
        </Grid>
      </Grid>
    );
  }

  let amountTotal = 0;
  let amountTotalAvailable = 0;
  let dollarUSLocale = Intl.NumberFormat("en-US");

  var tabla = (
    <Paper style={{ padding: 20, margin: 5 }}>
      <Typography variant={"h2"} color="primary">
        Estado de cuenta
      </Typography>
      {eventDepositsList && <DownloadItems jsonDashboard={jsonDashboard} />}
      {eventDepositsList && (
        <SaveAccountToMaster
          jsonDashboard={jsonDashboard}
          eventDepositsList={eventDepositsList}
        />
      )}
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Fecha</TableCell>
            <TableCell align="center">Tipo</TableCell>
            <TableCell align="center">Descripción</TableCell>
            <TableCell align="center">Monto</TableCell>
            <TableCell align="center">Documento</TableCell>
            {atleteAccount.perfil === 1 && (
              <TableCell align="center"></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {eventDepositsList &&
            Object.values(eventDepositsList)
              .sort((a, b) =>
                a.depositDate > b.depositDate
                  ? 1
                  : b.depositDate > a.depositDate
                  ? -1
                  : 0
              )
              .map((item, i) => {
                let amount = 0;
                let date = fecha(0);
                let amountAvailable = 0;

                if (item.type === "oxxoRegistryPercent") {
                  amount =
                    (item.amount / 100) * jsonDashboard.totalCompletoOxxo;
                  amountAvailable =
                    (item.amount / 100) *
                    jsonDashboard.totalCompletoOxxoDisponible;
                } else if (item.type === "cardRegistryPercent") {
                  amount = (item.amount / 100) * jsonDashboard.totalTarjeta;
                  amountAvailable =
                    (item.amount / 100) * jsonDashboard.totalTarjetaDisponible;
                } else if (item.type === "cashRegistryPercent") {
                  amount =
                    (item.amount / 100) * jsonDashboard.totalPagosEfectivo;
                } else if (item.type === "fixedAmount") {
                  amount = item.amount;
                  amountAvailable = item.amount;
                  date = item.depositDate;
                } else if (item.type === "deposit") {
                  amount = -item.amount;
                  amountAvailable = -item.amount;
                  date = item.depositDate;
                }
                amountTotal = amountTotal + amount;
                amountTotalAvailable = amountTotalAvailable + amountAvailable;
                let dollarUSLocale = Intl.NumberFormat("en-US");
                const amountFormat = dollarUSLocale.format(amount);

                return (
                  <TableRow key={i}>
                    <TableCell align="center" scope="row">
                      {date}
                    </TableCell>
                    <TableCell align="center" scope="row">
                      {item.type}
                    </TableCell>
                    <TableCell align="center" scope="row">
                      {item.description}
                    </TableCell>
                    <TableCell align="center" scope="row">
                      {amountFormat}
                    </TableCell>
                    <TableCell align="center" scope="row">
                      {item.document && (
                        <Button target="_blank" href={item.document}>
                          Ver
                        </Button>
                      )}
                    </TableCell>
                    {atleteAccount.perfil === 1 && (
                      <TableCell align="center">
                        <EditItem index={item.index} deposit={item} />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          <TableRow>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="right">TOTAL</TableCell>
            <TableCell align="center">
              {dollarUSLocale.format(Math.round(amountTotal * 100) / 100)}
            </TableCell>
            <TableCell align="center"></TableCell>
            {atleteAccount.perfil === 1 && (
              <TableCell align="center"></TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
            <TableCell align="right">TOTAL DISPONIBLE</TableCell>
            <TableCell align="center">
              {dollarUSLocale.format(
                Math.round(amountTotalAvailable * 100) / 100
              )}
            </TableCell>
            <TableCell align="center"></TableCell>
            {atleteAccount.perfil === 1 && (
              <TableCell align="center"></TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>

      {showFormToAdd}
    </Paper>
  );
  return tabla;
}
