// Initialize Firebase
// Initialize Firebase

import { initEvents } from "../data-store/actions/events-actions";
import rootReducer from "../data-store/reducers";
//import M from "materialize-css";
import { modalidadCateogoriaNameByCode } from "../services/utils";
import firebase from "firebase";
import "firebase/firestore";

const initializedFirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAtcbYiS6l_5yaNkACPUyvK8CcoIENc3Vk",
  authDomain: "kmetasfirebase.firebaseapp.com",
  databaseURL: "https://kmetasfirebase.firebaseio.com",
  projectId: "kmetasfirebase",
  storageBucket: "kmetasfirebase.appspot.com",
  messagingSenderId: "574644644844",
  appId: "1:574644644844:web:d03014ed12cc7a8414aec9",
});

const organizersDB = firebase.initializeApp(
  {
    databaseURL: "https://organizers-dashport.firebaseio.com/",
  },
  "organizersDB"
);
// const messaging = initializedFirebaseApp.messaging();
// messaging.usePublicVapidKey(
//   "BGolS3hABr2Nq2pcNR7lRMUW9R1Zu8RFdDDvHFdf_4Cg7zflQSiFQuYnPD3NWqwu8Yj2nognOVvJhZ19pJn3WAs"
// );

//export { messaging };

export var db = initializedFirebaseApp.database();
export let dbOrganizers = firebase.database(organizersDB);

export var firestore = initializedFirebaseApp.firestore();

export var dbRef = initializedFirebaseApp.database;

export var auth = initializedFirebaseApp.auth();

// Get a reference to the storage service, which is used to create references in your storage bucket
export var storage = initializedFirebaseApp.storage();

// Create a storage reference from our storage service
export var storageRef = storage.ref();

//globales
export const Competidores = "competidores/";
export const EventosAutorizadosRuta = "eventosAutorizados/";
export const EventosAutorizadosJuezRuta = "eventosAutorizadosJuez/";

export const EventosCompletos = "eventosCompletos/";
export const EventosLista = "eventosLista/";

//Especificas por evento
// publicas
export const Configuracion = "configuracion/";
export const Convocatoria = "convocatoria/";
export const web = "web/";
export const DetalleEvento = "detalleEvento/";
export const Resultados = "resultados/";
export const Modalidades = "modalidades/";
export const ModalidadesInscripciones = "modalidadesInscripcion/";
export const FotosEvento = "fotosEvento/";
export const CompetidoresEvento = "competidoresEvento/";
export const PuntosDeControl = "puntosDeControl/";
export const Ruta = "ruta/";
export const InscripcionesConfiguracion = "inscripcionesConfiguracion/";
export const OganizadoresEvento = "oganizadoresEvento/";
export const customStrings = "customStrings/";

export const CodigosDescuento = "codigosDescuento/";
export const Codigos = "codigos/";
export const GruposCodigos = "gruposCodigos/";

export const fotosEventoRuta = "fotosEvento/";
export const configuracionFotosRuta = "configuracionFotos/";
export const fotosRuta = "fotos/";
export const puntoFotograficoPrevRuta = "puntoFotografico_";

// privadas
export const Deposits = "deposits/";
export const DepositsList = "depositsList/";
export const UsersList = "usersList/";

export const CustomViews = "customViews/";

export const JuecesEvento = "juecesEvento/";
export const RegistrosJuez = "registrosJuez/";
export const ResultadosEnProceso = "resultadosEnProceso/";

// eventos Virtuales
export const users = "users";
export const virtualEvents = "virtualEvents";
export const participants = "participants";
export const trakingGPSParticipants = "trakingGPSParticipants";
export const myVirtualEvents = "myVirtualEvents";
export const myOrganizedVirtualEvents = "myOrganizedVirtualEvents";
export const eventPass = "eventPass";
export const activities = "activities";
export const activityDetails = "activityDetails";
export const traking = "traking";

var eventosTB = db.ref(EventosCompletos);

// seccion para recibir data de eventos anteriores
var DetalleEventoData;
var ConfiguracionEvento;
var ResultadosEvento;
var ModalidadesEvento;
var ModalidadesEventoSnap;

// seccion para nuevos inscritos.
export const inscritos = "inscritos/";
export const formsData = "formsData/";
export const folios = "folios/";
export const listadoInscritos = "listadoInscritos/";
export const resumenInscritos = "resumenInscritos/";
export const codigosInscritos = "codigosInscritos/";

// Event WEB
export const eventWeb = "web/";
// Event Acount
export const eventsAccount = "eventsAccount/";

// Business
export const business = "business/";

// TORNEOS - TOURNAMENTS
export const tournaments = "tournaments";
export const tournamentResults = "tournamentResults";
export const participantsList = "participantsList";

// TRACKING LINKS
export const trackingLinks = "trackingLinks/";

export const borrarInscritoFB = (
  eventoIndex,
  inscritoKey,
  ParticipantDetail,
  userID,
  atleteAccount,
  borrado
) => {
  var action;

  if (borrado) {
    action = 1;
  } else {
    console.log(borrado);
    action = 2;
  }

  var changesRegistry = changesRegistryFunc(
    inscritoKey,
    ParticipantDetail,
    userID,
    atleteAccount,
    action,
    ""
  );

  var updates = {};
  updates[
    "eventosCompletos/" +
      eventoIndex +
      "/inscritos/listadoInscritos/" +
      inscritoKey +
      "/borrado/"
  ] = borrado;
  updates[
    "eventosCompletos/" +
      eventoIndex +
      "/inscritos/listadoInscritos/" +
      inscritoKey +
      "/changesRegistry/"
  ] = changesRegistry;
  return db
    .ref()
    .update(updates)
    .then((snap) => {
      console.log(borrado);
    });
};

//##################################################################################
export const cambiarModGenCatFB = (
  eventSubEvents,
  JSONCambios,
  eventoIndex,
  inscritoKey,
  ParticipantDetail,
  userID,
  atleteAccount
) => {
  var modCatJson = modalidadCateogoriaNameByCode(
    eventSubEvents,
    JSONCambios.modalidad,
    JSONCambios.categoria,
    JSONCambios.genero
  );
  var modCatJsonAnt = modalidadCateogoriaNameByCode(
    eventSubEvents,
    JSONCambios.modalidadAnterior,
    JSONCambios.categoriaAnterior,
    JSONCambios.generoAnterior
  );

  var updates = {};
  if (JSONCambios.modalidadAnterior != JSONCambios.modalidad) {
    var valorAnterior = modCatJsonAnt.modalidad;
    var newValue = modCatJson.modalidad;
    var campo = "modalidad";
    console.log("cambio" + campo);
    var description = `Cambio Modalidad ${valorAnterior} a ${newValue}`;
    var action = 8;
    var changesRegistry = changesRegistryFunc(
      inscritoKey,
      ParticipantDetail,
      userID,
      atleteAccount,
      action,
      description
    );
    console.log(changesRegistry);
    updates[
      `eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/${campo}/`
    ] = JSONCambios.modalidad;
    updates[
      `eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/changesRegistry/`
    ] = changesRegistry;

    //M.toast(description, 4000);
  }
  if (JSONCambios.categoriaAnterior != JSONCambios.categoria) {
    var valorAnterior = modCatJsonAnt.categoria;
    var newValue = modCatJson.categoria;
    var campo = "categoria";
    console.log("cambio" + campo);
    var description = `Cambio categoria ${valorAnterior} a ${newValue}`;
    var action = 8;
    var changesRegistry = changesRegistryFunc(
      inscritoKey,
      ParticipantDetail,
      userID,
      atleteAccount,
      action,
      description
    );
    console.log(changesRegistry);
    updates[
      `eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/${campo}/`
    ] = JSONCambios.categoria;
    updates[
      `eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/changesRegistry/`
    ] = changesRegistry;
    //M.toast(description, 4000);
  }
  if (JSONCambios.generoAnterior != JSONCambios.genero) {
    var valorAnterior = JSONCambios.generoAnterior;
    var newValue = JSONCambios.genero;
    var campo = "rama";
    console.log("cambio" + campo);
    var description = `Cambio genero ${valorAnterior} a ${newValue}`;
    var action = 8;
    var changesRegistry = changesRegistryFunc(
      inscritoKey,
      ParticipantDetail,
      userID,
      atleteAccount,
      action,
      description
    );
    console.log(changesRegistry);
    updates[
      `eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/${campo}/`
    ] = JSONCambios.genero;
    updates[
      `eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/changesRegistry/`
    ] = changesRegistry;
    //M.toast(description, 4000);
  }

  return db
    .ref()
    .update(updates)
    .then((snap) => {
      //M.toast("Cambios Guardados", 4000);
    });
};
//##################################################################################
export const inputChanges = (
  eventoIndex,
  inscritoKey,
  ParticipantDetail,
  userID,
  atleteAccount,
  inputObject,
  newValue
) => {
  //  function saveChange(event, id, titulo,
  //campo, valorAnterior, idRegistro, tipoCampo, eventoIndex){

  var campo = inputObject.iDCampo;
  var etiqueta = inputObject.etiqueta;
  var valorAnterior = ParticipantDetail[campo];
  console.log(valorAnterior);

  var cambio = false;
  if (inputObject.tipoCampo.localeCompare("checkbox") == 0) {
    cambio = newValue != valorAnterior;
  } else {
    cambio = newValue.localeCompare(valorAnterior) != 0;
  }

  if (cambio) {
    var jsonAction = {
      correo: 3,
      nombres: 4,
      apellidos: 5,
      telefono: 6,
      fechaNacimiento: 7,
      modalidad: 8,
      rama: 9,
      categoria: 10,
    };

    var action = jsonAction[campo];
    if (action == undefined) {
      action = 11;
    }

    var description = `Cambio ${etiqueta} de ${valorAnterior} a ${newValue}`;
    var changesRegistry = changesRegistryFunc(
      inscritoKey,
      ParticipantDetail,
      userID,
      atleteAccount,
      action,
      description
    );

    var updates = {};
    updates[
      `eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/${campo}/`
    ] = newValue;
    updates[
      "eventosCompletos/" +
        eventoIndex +
        "/inscritos/listadoInscritos/" +
        inscritoKey +
        "/changesRegistry/"
    ] = changesRegistry;

    return db
      .ref()
      .update(updates)
      .then((snap) => {
        //M.toast(description, 4000);
      });
  } else {
    //M.toast("No hubo cambios", 4000);
  }
};
//##################################################################################
export const deleteParticipantNumber = (
  eventoIndex,
  inscritoKey,
  ParticipantDetail,
  userID,
  atleteAccount
) => {
  var action = 20;
  var description = `Liberar folio ${ParticipantDetail.numero}`;

  var changesRegistry = changesRegistryFunc(
    inscritoKey,
    ParticipantDetail,
    userID,
    atleteAccount,
    action,
    description
  );

  console.log(eventoIndex);
  console.log(inscritoKey);
  console.log(ParticipantDetail.numero);

  var updates = {};
  updates[
    `/eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/numero/`
  ] = "Liberado";
  updates[
    `/eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/liberado/`
  ] = true;
  updates[
    `eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/changesRegistry/`
  ] = changesRegistry;
  updates[
    `eventosCompletos/${eventoIndex}/inscritos/folios/num_${ParticipantDetail.numero}/`
  ] = null;
  return firebase
    .database()
    .ref()
    .update(updates);
};
//##################################################################################
export const changesRegistryFunc = (
  inscritoKey,
  ParticipantDetail,
  userID,
  atleteAccount,
  action,
  description
) => {
  var timestamp = new Date().getTime();
  console.log(inscritoKey);
  var changesRegistry = ParticipantDetail.changesRegistry;
  if (changesRegistry == undefined) {
    changesRegistry = {};
    ParticipantDetail.changesRegistry = {};
  }
  var cambios = {
    iduser: userID,
    nombresUser: atleteAccount.nombres,
    apellidosUser: atleteAccount.apellidos,
    date: timestamp,
    action: action,
    description: description,
  };

  changesRegistry[timestamp + "_ts"] = cambios;
  ParticipantDetail.changesRegistry[timestamp + "_ts"] = cambios;
  return changesRegistry;
};
