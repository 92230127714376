import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import EventRegistryPublic from "./EventRegistryPublic";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
  buttons2: {
    margin: 30,
    width: "250px",
    color: "white",
  },
}));

export default function EventRegistry(props) {
  const {
    bgColor,
    eventLogo,
    image,
    title,
    renderType,
    eventIdDashport,
    textBottom,
  } = props.componentConfig;
  // const eventWebPublicIndex = useSelector(
  //   (state) => state.organizer.eventWebPublicIndex
  // );

  //trackEvent({ event: { event: "PageView", page: eventId } });

  return (
    <div style={{ backgroundColor: bgColor }} id="topForm">
      <div id="topForm" style={{ height: "10px" }}></div>
      <EventRegistryPublic
        eventIdDashport={eventIdDashport}
        title={title}
        eventLogo={eventLogo}
        image={image}
        renderType={renderType}
        textBottom={textBottom}
      />
    </div>
  );
}

EventRegistry.displayName = "Vista para Incripciones a un evento";

export const componentDescription = {
  componentConfig: {
    eventLogo: {
      label: "Logo del registro",
      errorMessage: "",
      fieldName: "eventLogo",
      type: "photo",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    image: {
      label: "Imagen de inscripcion",
      errorMessage: "",
      fieldName: "image",
      type: "photo",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    eventIdDashport: {
      label: "ID del evento en Dashport",
      errorMessage: "",
      fieldName: "eventIdDashport",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    renderType: {
      label: "Tipo de vista a para mostra el formulario",
      errorMessage: "Campo Requerido",
      fieldName: "renderType",
      type: "select",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      optionsArray: [
        { label: "Vista resumida", value: "view" },
        { label: "Vista detallada", value: "viewFull" },
        { label: "Imagen de Inscripciones", value: "image" },
      ],
    },

    title: {
      label: "Titulo de la sección",
      errorMessage: "",
      fieldName: "title",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    textBottom: {
      label: "Texto al final de la sección",
      errorMessage: "",
      fieldName: "textBottom",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    bgColor: {
      label: "Color de Fondo",
      errorMessage: "",
      fieldName: "bgColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  },
};
